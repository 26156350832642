<template>
  <v-app id="inspire">
    <v-main>
      <v-container fill-height fluid>
        <v-layout align-center justify-center>
          <v-flex md4 sm8 xs12>
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark>
                <v-toolbar-title>Login form</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                      v-model="email"
                      label="Login"
                      prepend-icon="mdi-account"
                      type="text"
                  ></v-text-field>
                  <v-text-field
                      label="Password"
                      v-model="password"
                      prepend-icon="mdi-lock"
                      type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="login()">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {AuthService} from "@/services/auth-service";

export default {
  name: 'Login',
  data() {
    return {
      email: "",
      password: ""
    }
  },
  props: {
    source: String,
  },
  methods: {
    async login() {
      await AuthService.login(this.email,this.password)
    }
  }
};
</script>

<style></style>
